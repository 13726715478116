// Generated by Framer (5c93d09)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qs8SMxorF"];

const serializationHash = "framer-6YwC9"

const variantClassNames = {qs8SMxorF: "framer-v-5u2nrb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qs8SMxorF", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6YwC9", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-5u2nrb", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qs8SMxorF"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-ju19c0"} data-framer-name={"Union"} layout={"position"} layoutDependency={layoutDependency} layoutId={"S5zuaYurX"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 19\"><path d=\"M 2.286 6.75 L 2.732 6.75 L 2.732 6.281 C 2.732 3.434 4.925 1.125 7.632 1.125 L 8.522 1.125 C 11.228 1.125 13.422 3.434 13.422 6.281 L 13.422 6.75 L 13.867 6.75 C 14.605 6.75 15.204 7.38 15.204 8.156 L 15.204 12.844 C 15.204 15.691 13.01 18 10.304 18 L 5.85 18 C 3.144 18 0.95 15.691 0.95 12.844 L 0.95 8.156 C 0.95 7.38 1.548 6.75 2.286 6.75 Z M 5.404 6.75 L 10.749 6.75 L 10.749 6.281 C 10.749 4.987 9.752 3.937 8.522 3.937 L 7.632 3.937 C 6.401 3.937 5.404 4.987 5.404 6.281 Z\" fill=\"var(--token-3ab2d8b6-b506-4374-a6e8-5ad0c59c1eff, rgb(102, 102, 102)) /* {&quot;name&quot;:&quot;Text&quot;} */\"></path></svg>"} svgContentId={1862267972} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6YwC9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6YwC9 .framer-zvm2df { display: block; }", ".framer-6YwC9 .framer-5u2nrb { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 24px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 24px; }", ".framer-6YwC9 .framer-ju19c0 { flex: none; height: 19px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6YwC9 .framer-5u2nrb { gap: 0px; } .framer-6YwC9 .framer-5u2nrb > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6YwC9 .framer-5u2nrb > :first-child { margin-left: 0px; } .framer-6YwC9 .framer-5u2nrb > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framervf1F7llpp: React.ComponentType<Props> = withCSS(Component, css, "framer-6YwC9") as typeof Component;
export default Framervf1F7llpp;

Framervf1F7llpp.displayName = "CMS / Lock";

Framervf1F7llpp.defaultProps = {height: 24, width: 24};

addFonts(Framervf1F7llpp, [])